<template>
    <v-select
      v-model="select"
      :items="selectItems"
      item-title="language"
      item-value="value"
      :label="$t('global.language')"
      class="w-25"
      @update:model-value="getTemplatesByLanguage"></v-select>
    <v-select
      id="selectItemsPerPage"
      v-model="selectedLimit"
      :items="limits"
      :label="$t('global.number-of-items-per-page')"
      class="w-25"
      @update:model-value="setNumberOfMetaTemplatesPerPage"></v-select>
    <div class="text-right mb-4">
      <v-btn  :to="{ name: 'meta_detail_template', params: { type: 'title'}}"
              size="small" variant="outlined">
        {{ $t("global.create-meta-title") }}</v-btn>
    </div>
    <v-table>
      <thead>
        <tr>
          <th class="text-left w-33">
            {{ $t("global.title") }}
          </th>
          <th class="text-left w-33">
            {{ $t("global.description") }}
          </th>
          <th class="text-right w-33">
            {{ $t("global.actions") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :key="key"
          v-for="(item, key) in items"
        >
          <td class="text-left w-33">{{ item.translated?.name || item.name }}</td>
          <td class="text-left w-33">{{ item.translated?.description || item.description }}</td>
          <td class="text-right w-33">
            <div class="d-flex justify-end">
              <v-btn :to="{ name: 'meta_detail_template',
                          params: { type: 'title', id: item.id }}"
                     size="small" variant="outlined">
                {{ $t("global.edit") }}</v-btn>
              <div>
                <v-dialog
                  v-model="dialog"
                  persistent
                  width="auto"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      color="red"
                      v-bind="props"
                      size="small"
                      variant="outlined"
                      class="ms-1"
                      @click="store.commit('setIdOfDeleteItem', item.id)"
                    >
                      {{ $t('global.delete') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6">
                      {{ $t('global.check-delete-title') }}
                    </v-card-title>
                    <v-card-text>{{ $t('global.check-delete') }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey-darken-2"
                        variant="text"
                        @click="dialog = false"
                      >
                        {{ $t("global.cancel") }}
                      </v-btn>
                      <v-btn
                        color="red"
                        variant="text"
                        @click="deleteTemplateById"
                      >
                        {{ $t("global.delete") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
      <v-container>
        <v-row justify="center">
          <v-col cols="6">
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                class="my-4"
                :length="Math.ceil((store.getters.getMetaTitleTemplates).length / selectedLimit)"
                @update:model-value="updatePageOfItems"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
// eslint-disable-next-line import/extensions,import/no-unresolved,no-unused-vars
import { getLocale } from '@/locales/sw-locale';
import store from '@/store';
import { data, notification } from '@shopware-ag/admin-extension-sdk';

export default {
  name: 'TitleView',
  computed: {
    store() {
      return store;
    },
  },

  data() {
    return {
      items: null,
      language: null,
      select: null,
      dialog: false,
      selectItems: [],
      page: 1,
      limits: [10, 25, 50, 75, 100],
      selectedLimit: 10,
    };
  },

  created() {
    this.onCreatedComponent();
  },

  methods: {
    async onCreatedComponent() {
      if (store.getters.getSelectItems.length === 0) {
        this.getAllLanguages();
      } else {
        this.selectItems = store.getters.getSelectItems;
      }
      if (store.getters.getSelectValue !== null) {
        this.select = store.getters.getSelectValue;
      } else {
        this.select = (await this.getLanguage()).valueOf();
      }
      this.getTemplatesByLanguage();
    },

    async getTemplatesByLanguage() {
      const fishnMetaTitleRepository = data.repository('ce_fishn_meta_title_template');
      const fishnMetaDescRepository = data.repository('ce_fishn_meta_desc_template');
      const criteria = new data.Classes.Criteria();

      store.commit('setSelectValue', this.select);
      store.commit('setContext', this.select);

      if (store.getters.getLanguageId !== store.getters.getContext.languageId) {
        store.commit('setLanguageId', store.getters.getContext.languageId);
        store.commit('setMetaTitleTemplates', await fishnMetaTitleRepository.search(criteria, store.getters.getContext));
        store.commit('setMetaDescTemplates', await fishnMetaDescRepository.search(criteria, store.getters.getContext));
      }
      this.items = (store.getters.getMetaTitleTemplates).slice(0, this.selectedLimit);
    },

    async getLanguage() {
      const locale = (await getLocale()).valueOf();
      const localeRepository = data.repository('locale');
      const languageRepository = data.repository('language');
      const criteria = new data.Classes.Criteria();
      criteria.addFilter(data.Classes.Criteria.equals('code', locale));
      const localeId = await localeRepository.search(criteria);
      const criteriaLanguage = new data.Classes.Criteria();
      criteriaLanguage.addFilter(data.Classes.Criteria.equals('localeId', localeId.first().id));
      const language = await languageRepository.search(criteriaLanguage);
      // eslint-disable-next-line no-return-await
      return language.first().id;
    },

    setNumberOfMetaTemplatesPerPage() {
      if (this.selectedLimit) {
        // eslint-disable-next-line max-len
        this.items = (store.getters.getMetaTitleTemplates).slice(0, this.selectedLimit);
      }
    },

    updatePageOfItems() {
      const startIndex = (this.page - 1) * this.selectedLimit;
      const endIndex = startIndex + this.selectedLimit;
      this.items = (store.getters.getMetaTitleTemplates).slice(startIndex, endIndex);
    },

    deleteTemplateById() {
      const id = store.getters.getIdOfDeleteItem;
      const fishnMetaTitleRepository = data.repository('ce_fishn_meta_title_template');
      this.$store.dispatch('deleteMetaTitleTemplates', { id });
      this.items = (store.getters.getMetaTitleTemplates).slice(0, this.selectedLimit);
      fishnMetaTitleRepository.delete(id);
      store.commit('setIdOfDeleteItem', '');
      this.dialog = false;

      notification.dispatch({
        // eslint-disable-next-line no-undef
        title: 'FishnSeoGenerator',
        message: this.$t('global.meta-template-successfully-deleted'),
        variant: 'success',
      });
    },

    async getAllLanguages() {
      const languageRepository = data.repository('language');
      const criteria = new data.Classes.Criteria();
      const languages = languageRepository.search(criteria);
      (await languages).forEach((result) => {
        this.selectItems.push({ language: result.name, value: result.id });
      });

      store.commit('setSelectItems', this.selectItems);
    },
  },
};
</script>
