<template>
  <v-container>
      <nav>
        <router-link to="/">{{ $t("global.meta-title") }}</router-link> |
        <router-link to="/description">{{ $t("global.meta-description") }}</router-link> |
        <router-link to="/configuration">{{ $t("global.configuration") }}</router-link>
      </nav>
      <router-view/>
  </v-container>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: rgb(87, 217, 163);
}
</style>
<script setup>
</script>
