import { createApp } from 'vue';
// eslint-disable-next-line import/order
import App from './App.vue';
// eslint-disable-next-line import/order
import router from './router';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import 'vuetify/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createVuetify } from 'vuetify';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as components from 'vuetify/components';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as directives from 'vuetify/directives';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import { getLocale } from './locales/sw-locale';
import store from './store';

const vuetify = createVuetify({
  components,
  directives,
});

getLocale().then((res) => {
  const i18n = createI18n({
    locale: res,
    fallbackLocale: 'en-GB',
    messages: { en, de },
  });

  createApp(App).use(store).use(router).use(vuetify)
    .use(i18n)
    .mount('#app');
});

window.parent.postMessage('sw-app-loaded', '*');
