// eslint-disable-next-line import/no-extraneous-dependencies
import { createStore } from 'vuex';

export default createStore({
  state: {
    languageId: null,
    context: null,
    metaTitleTemplates: [],
    metaDescTemplates: [],
    selectValue: null,
    templateId: null,
    metaTemplate: null,
    systemConfig: [],
    selectItems: [],
    idOfDeleteItem: null,
  },

  getters: {
    getLanguageId(state) {
      return state.languageId;
    },
    getContext(state) {
      return state.context;
    },
    getMetaTitleTemplates(state) {
      return state.metaTitleTemplates;
    },
    getMetaDescTemplates(state) {
      return state.metaDescTemplates;
    },
    getSelectValue(state) {
      return state.selectValue;
    },
    getTemplateId(state) {
      return state.templateId;
    },
    getSystemConfig(state) {
      return state.systemConfig;
    },
    getSelectItems(state) {
      return state.selectItems;
    },
    getIdOfDeleteItem(state) {
      return state.idOfDeleteItem;
    },
  },

  mutations: {
    setLanguageId(state, languageId) {
      state.languageId = languageId;
    },
    setContext(state, id) {
      state.context = {
        inheritance: true,
        languageId: id,
      };
    },
    setMetaTitleTemplates(state, template) {
      state.metaTitleTemplates = template;
    },
    setMetaDescTemplates(state, templates) {
      state.metaDescTemplates = templates;
    },
    setNewMetaTitleTemplate(state, template) {
      state.metaTitleTemplates = state.metaTitleTemplates.concat(template);
    },
    setNewMetaDescTemplate(state, template) {
      state.metaDescTemplates = state.metaDescTemplates.concat(template);
    },
    setSelectValue(state, value) {
      state.selectValue = value;
    },
    setTemplateId(state, templateId) {
      state.templateId = templateId;
    },
    setSystemConfig(state, config) {
      state.systemConfig = config;
    },
    setSelectItems(state, selectItems) {
      state.selectItems = selectItems;
    },
    setIdOfDeleteItem(state, idOfDeleteItem) {
      state.idOfDeleteItem = idOfDeleteItem;
    },
  },

  actions: {
    updateMetaTitleTemplate({ commit, state }, { id, newTemplate }) {
      const templates = state.metaTitleTemplates.map((result) => {
        if (result.id === id) {
          return newTemplate;
        }
        return result;
      });

      commit('setMetaTitleTemplates', templates);
    },
    updateMetaDescTemplate({ commit, state }, { id, newTemplate }) {
      const templates = state.metaDescTemplates.map((obj) => {
        if (obj.id === id) {
          return newTemplate;
        }
        return obj;
      });
      commit('setMetaDescTemplates', templates);
    },

    deleteMetaTitleTemplates({ commit, state }, { id }) {
      const templates = state.metaTitleTemplates.filter((obj) => obj.id !== id);
      commit('setMetaTitleTemplates', templates);
    },

    deleteMetaDescTemplates({ commit, state }, { id }) {
      const templates = state.metaDescTemplates.filter((obj) => obj.id !== id);
      commit('setMetaDescTemplates', templates);
    },
  },
});
