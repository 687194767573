import { createRouter, createWebHistory } from 'vue-router';
import Title from '../views/MetaTitleListView.vue';

const routes = [
  {
    path: '/',
    name: 'title',
    component: Title,
  },
  {
    path: '/meta-detail-template/:type/:id?',
    name: 'meta_detail_template',
    component: () => import('../views/MetaDetailTemplate.vue'),
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfigurationTemplateView.vue'),
  },
  {
    path: '/description',
    name: 'description',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MetaDescriptionListView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
